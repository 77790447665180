.page {
  display: flex;
  flex-direction: column;
  padding-bottom: 33px;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
}

.title {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.content {
  padding-top: 18px;
  flex: 1 0 auto;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.bg {
  position: absolute;
  bottom: -50%;
  width: 100%;
}
