.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px 18px;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #34303d;
  background-color: #f3f3f3;
  border-radius: 24px;
  border: none;
  gap: 6px;
  z-index: 1;
  position: relative;
  margin-top: 4px;
}

.link.dark {
  color: #f3f3f3;
  background-color: #34303d;
}
