.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 18px;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #34303d;
  background-color: #f3f3f3;
  border-radius: 15px;
  border: none;
  gap: 6px;
}
