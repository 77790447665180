.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  padding: 8px 16px;
}

.popup {
  bottom: 8px;
  padding: 28px 16px 16px 16px;
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background-image: url(../../images/cross.svg);
  position: absolute;
  top: 14px;
  right: 16px;
}

.warningImg {
  width: 48px;
  height: 48px;
  margin-bottom: 14px;
}

.title {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #34303d;
  margin-bottom: 12px;
}

.text {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #949494;
  margin-bottom: 24px;
}

.button {
  padding: 10px 32px;
  border-radius: 24px;
  background-color: #34303d;
  color: #fff;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  width: 100%;
  border: none;
}
