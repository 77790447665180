.card {
  height: 352px;
  min-width: 85% !important;
  max-width: 272px !important;
  border-radius: 15px;
  overflow: hidden;
  background-color: #f3f3f3;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;

  /* background-image: url(../../images/smell-1.png);
  background-size: cover;
  background-repeat: no-repeat; */
}

.img {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  bottom: 0;
  right: 0;
}

.title {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #34303d;
  z-index: 1;
  margin-bottom: 8px;
}

.text {
  font-family: IQOS;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #524f5a;
  margin-bottom: auto;
  z-index: 1;
}
