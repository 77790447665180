.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url(../../images/ellipse.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom -30% right 0px;
}

.title {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #34303d;
}

.container {
  padding: 14px 16px 0 16px;
}

.content {
  background-image: url(../../images/bad-quality.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom 0px left 12px;
  min-width: 342px;
  width: 100%;
  aspect-ratio: 1;
  background-color: #f3f3f3;
  border-radius: 15px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: 32px;
}

.text {
  font-family: IQOS;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #34303d;
}
