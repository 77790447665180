.promoBox {
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.box {
  background: linear-gradient(
      198.07deg,
      rgba(233, 255, 255, 0) 25.56%,
      rgba(226, 255, 255, 0.75514) 73.61%,
      #c8f7f7 97.14%
    ),
    #f9f9f9;
  padding: 16px 16px 62px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  height: 200px;
}

.boxText {
  font-family: IQOS;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #34303d;
  background: linear-gradient(180deg, #6ee4e4 0%, #00d1d2 100%);
  padding: 6px 12px;
  width: fit-content;
  border-radius: 50px;
  z-index: 1;
}

.boxTitle {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #34303d;
  max-width: 60%;
  z-index: 1;
}

.img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.container {
  padding: 19px 16px 24px 16px;
}

.text {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #949494;
  margin-top: 8px;
  margin-bottom: 24px;
}

.link {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #00d1d2;
}

.copyConfirmed {
  display: flex;
  opacity: 0;
  gap: 16px;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  position: absolute;
  bottom: 30px;
  z-index: 1;
  background-color: #fff;
  width: calc(100% - 32px);
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #34303d;
  transition: all 0.5s cubic-bezier(0, 0, 1, 1);
}

.copyConfirmed.active {
  opacity: 1;
}
