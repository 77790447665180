.link {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px;
  background: #d1faf9;
  box-shadow: 4px 4px 11px rgba(31, 44, 45, 0.11);
  border-radius: 15px;

  /* margin: 0 16px; */
}

.circle {
  border-radius: 50%;
  background: #fff;
  min-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.text {
  font-family: IQOS;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #34303d;
}
.text.blue {
  color: #00d1d2;
}
