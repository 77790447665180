.page {
  display: flex;
  flex-direction: column;
  padding-bottom: 33px;
  /* height: 100vh; */
  position: relative;
  /* overflow: hidden; */
}

.title {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.content {
  padding: 18px 16px 0 16px;
  flex: 1 0 auto;
  gap: 24px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.bg {
  position: absolute;
  bottom: -65px;
  width: 100%;
}
