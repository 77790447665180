.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 12px 16px 13px 19px;
  background: transparent;
  min-height: 73px;
}

.text {
  color: #b8b7bb;
  font-family: 'IQOS';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
}

.text.transparent {
  color: #524f5a;
}
