.page {
  background: linear-gradient(180deg, #6ee4e4 0%, #00d1d2 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  padding: 14px 16px 0 16px;
}
