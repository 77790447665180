.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url(../../images/ellipse.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom -200px right 0px;
}

.container {
  padding: 14px 16px 14px 16px;
  /* margin: 0 16px; */
  display: flex;
  flex-direction: column;
}

.title {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-bottom: 32px;
}

.dots {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 8px;
  height: 8px;
  opacity: 0.3;
  background-color: #34303d;
  border: none;
  border-radius: 50%;
}

.dot.active {
  opacity: 1;
}

.bg {
  position: absolute;
  bottom: -30%;
  width: 100%;
  z-index: -1;
}

.footnote {
  font-family: IQOS;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #637679;
  margin-top: 14px;
}

@media screen and (min-height: 700px) {
  .page {
    height: 100vh;
  }
}
