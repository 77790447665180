.promocodeBox {
  border: 1px solid #e1e0e2;
  padding: 16px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.promocodeTitle {
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #949494;
}

.promocodeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promocode {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #34303d;
}

.copy {
  border: none;
  background-color: transparent;
}
