.page {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #6ee4e4 0%, #00d1d2 100%);
  padding-bottom: 14px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 16px;
  position: relative;
}

.carouselContainer {
  padding-left: 16px;
}

.title {
  font-family: IQOS;
  font-size: 34px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #34303d;
}

.text {
  font-family: IQOS;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.dots {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 8px;
  height: 8px;
  opacity: 0.3;
  background-color: #34303d;
  border: none;
  border-radius: 50%;
}

.dot.active {
  opacity: 1;
}

.footnote {
  font-family: IQOS;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  color: #546669;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  justify-items: center;
}
