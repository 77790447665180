.card {
  height: 200px;

  max-width: 167px !important;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f3f3f3;
  position: relative;
  padding: 14px;
  display: flex;
  flex-direction: column;

  /* background-image: url(../../images/smell-1.png);
  background-size: cover;
  background-repeat: no-repeat; */
}

.card.dark {
  background: linear-gradient(180deg, #34303d 0%, #1c1c2b 35.25%, #171727 100%);
}
.img {
  position: absolute;
  /* width: 100%; */
  height: 100%;
  bottom: 0;
  right: 0;
}

.title {
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: left;
  color: #34303d;
  z-index: 1;
}

.title.dark {
  color: #f9f9f9;
}
.text {
  font-family: IQOS;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #524f5a;
  margin-bottom: auto;
  z-index: 1;
}

.button {
  width: fit-content;
  height: 52px;
  border-radius: 100px;
  background-color: #34303d;
  border: none;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: IQOS;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #fff;
  padding: 17px 14px;
  width: 90%;
}
